/*  */
.range-slider {
  max-width: 580px;
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.single-thumb .range-slider__range {
  border-radius: 6px;
}

/* Yellow Range Slider */
#range-slider-yellow {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E") #333;
}

#range-slider-yellow .range-slider__range {
  background: url(./gradiente.jpg) no-repeat top left;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #414141;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 16px;
}

html.dark #range-slider-yellow .range-slider__thumb {
  background: #ffffff;
  transition: transform 0.3s;
}

html.dark #range-slider-yellow {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E") #9e9e9e;
}